import { withCompController } from '@wix/editor-elements-integrations';
import {
  IMatrixGalleryControllerProps,
  IMatrixGalleryMapperProps,
  IMatrixGalleryProps,
} from '../MatrixGallery.types';

export default withCompController<
  IMatrixGalleryMapperProps,
  IMatrixGalleryControllerProps,
  IMatrixGalleryProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    setItemToFocusIndex: index => {
      controllerUtils.updateProps({
        itemToFocusIndex: index,
      });
    },
    setNumberOfExtraRows: extraRows => {
      controllerUtils.updateProps({
        numberOfExtraRows: extraRows,
      });
    },
  };
});
