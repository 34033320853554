export const TestIds = {
  itemsContainer: 'matrix-gallery-items-container',
  showMoreButton: 'matrix-gallery-show-more-button',
} as const;

export const translations = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  MATRIX_GALLERY_ARIA_LABEL_KEY: 'MatrixGallery_AriaLabel_TopLevel',
  MATRIX_GALLERY_ARIA_LABEL_DEFAULT: 'Matrix gallery',
};
