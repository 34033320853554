import * as React from 'react';
import { PanelProps } from '../MatrixGalleryItem.types';
import { TestIds } from '../../commons/constants';

const Panel: React.FC<PanelProps> = props => {
  const { title, description, skinsStyle } = props;

  return (
    <div data-testid={TestIds.panel} className={skinsStyle.panel}>
      <div className={skinsStyle.panelWrap}>
        {!!title && (
          <div data-testid={TestIds.title} className={skinsStyle.title}>
            {title}
          </div>
        )}
        {!!description && (
          <p
            data-testid={TestIds.description}
            className={skinsStyle.description}
          >
            {description}
          </p>
        )}
      </div>
    </div>
  );
};

export default Panel;
